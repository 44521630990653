function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(e, r, t) {return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e;}function _toPropertyKey(t) {var i = _toPrimitive(t, "string");return "symbol" == typeof i ? i : i + "";}function _toPrimitive(t, r) {if ("object" != typeof t || !t) return t;var e = t[Symbol.toPrimitive];if (void 0 !== e) {var i = e.call(t, r || "default");if ("object" != typeof i) return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return ("string" === r ? String : Number)(t);}



const getHighlightsFromFile = (
selections,
property,
color = 'lightyellow') =>
{var _selectionsForPropert, _selectionsForPropert2, _selectionsForPropert3;
  const selectionsForProperty = selections.filter((selection) => selection.name === property)[0];

  const selectionText = selectionsForProperty === null || selectionsForProperty === void 0 ? void 0 : (_selectionsForPropert = selectionsForProperty.selection) === null || _selectionsForPropert === void 0 ? void 0 : _selectionsForPropert.text;

  const highlights = {};

  selectionsForProperty === null || selectionsForProperty === void 0 ? void 0 : (_selectionsForPropert2 = selectionsForProperty.selection) === null || _selectionsForPropert2 === void 0 ? void 0 : (_selectionsForPropert3 = _selectionsForPropert2.selectionRectangles) === null || _selectionsForPropert3 === void 0 ? void 0 : _selectionsForPropert3.forEach((rectangle) => {
    const { page } = rectangle;
    if (!page) return;

    if (highlights[page]) {
      highlights[page][0].textSelection.selectionRectangles.push({
        left: rectangle.left,
        top: rectangle.top,
        width: rectangle.width,
        height: rectangle.height,
        regionId: page
      });
    } else {
      highlights[page] = [
      {
        key: `${page}`,
        textSelection: {
          selectionRectangles: [
          {
            left: rectangle.left,
            top: rectangle.top,
            width: rectangle.width,
            height: rectangle.height,
            regionId: page
          }],

          text: selectionText
        },
        color
      }];

    }
  });

  return highlights;
};

const getHighlightsFromSelection = (
selection,
color = 'lightyellow') =>
{
  const highlights = {};

  const { text } = selection;

  selection.selectionRectangles.forEach((rectangle) => {
    const page = rectangle.regionId;

    if (!page) return;

    if (highlights[page]) {
      highlights[page][0].textSelection.selectionRectangles.push({
        left: rectangle.left,
        top: rectangle.top,
        width: rectangle.width,
        height: rectangle.height,
        regionId: page
      });
    } else {
      highlights[page] = [
      {
        key: `${page}`,
        textSelection: {
          selectionRectangles: [
          {
            left: rectangle.left,
            top: rectangle.top,
            width: rectangle.width,
            height: rectangle.height,
            regionId: page
          }],

          text
        },
        color
      }];

    }
  });

  return highlights;
};

const updateFileSelection = (
property,
currentSelections,
newSelection) =>
{
  const result = currentSelections || [];

  if (!newSelection) {
    return result;
  }

  const isNewSelection = !(currentSelections !== null && currentSelections !== void 0 && currentSelections.find(
    (selection) => selection.propertyID === property.id || selection.name === property.name
  ));

  const timestamp = new Date().toString();

  if (isNewSelection && property) {
    return [
    ...result, _objectSpread(_objectSpread({

      name: property.name },
    property.id && { propertyID: property.id }), {}, {
      timestamp,
      selection: {
        text: newSelection.text,
        selectionRectangles: newSelection.selectionRectangles.map((rectangle) => {
          const formattedRectangle = _objectSpread(_objectSpread({},
          rectangle), {}, {
            page: rectangle.regionId });

          delete formattedRectangle.regionId;

          return formattedRectangle;
        })
      } })];


  }

  const updatedSelections = result.map((selection) => {
    if (selection.propertyID === property.id || selection.name === property.name) {
      return _objectSpread(_objectSpread({},
      selection), {}, {
        timestamp,
        selection: {
          text: newSelection.text,
          selectionRectangles: newSelection.selectionRectangles.map((rectangle) => {
            const formattedRectangle = _objectSpread(_objectSpread({},
            rectangle), {}, {
              page: rectangle.regionId });

            delete formattedRectangle.regionId;

            return formattedRectangle;
          })
        } });

    }

    return selection;
  });

  return updatedSelections;
};

const deleteFileSelection = (
property,
currentSelections) =>
{
  if (!currentSelections) {
    return [];
  }

  if (!property.name) {
    return currentSelections;
  }

  const updatedSelections = currentSelections.filter((selection) => {
    if (property.id) {
      return property.id !== selection.propertyID;
    }
    return property.name !== selection.name;
  });

  return updatedSelections;
};

const adjustSelectionsToScale = (
selection,
scalingFactor,
normalize) =>
{var _scaledSelection$sele;
  if (scalingFactor === 1 || scalingFactor === 0) {
    return selection;
  }

  const scaledSelection = _objectSpread({}, selection);

  if ((_scaledSelection$sele = scaledSelection.selectionRectangles) !== null && _scaledSelection$sele !== void 0 && _scaledSelection$sele.length) {
    scaledSelection.selectionRectangles = selection.selectionRectangles.map((rectangle) => {
      const left = rectangle.left || 0;
      const top = rectangle.top || 0;
      const width = rectangle.width || 0;
      const height = rectangle.height || 0;
      return _objectSpread(_objectSpread({},
      rectangle), {}, {
        left: normalize ? left / scalingFactor : left * scalingFactor,
        top: normalize ? top / scalingFactor : top * scalingFactor,
        width: normalize ? width / scalingFactor : width * scalingFactor,
        height: normalize ? height / scalingFactor : height * scalingFactor });

    });
  }

  return scaledSelection;
};

export {
  getHighlightsFromFile,
  getHighlightsFromSelection,
  updateFileSelection,
  deleteFileSelection,
  adjustSelectionsToScale };